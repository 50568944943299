<template>
  <div>
    <div class="text-center mt-8" v-if="imgHead != 0">
      Head
      <v-row>
        <v-col cols="3" v-for="(image, i) in imgHead" :key="i">
          <v-img max-height="400" contain :src="image.src" />
        </v-col>
      </v-row>
    </div>
    <div class="text-center mt-8" v-if="imgHalf != 0">
      Half
      <v-row>
        <v-col cols="3" v-for="(image, i) in imgHalf" :key="i">
          <v-img max-height="400" contain :src="image.src" />
        </v-col>
      </v-row>
    </div>
    <div class="text-center mt-8" v-if="imgFull != 0">
      Full
      <v-row>
        <v-col cols="3" v-for="(image, i) in imgFull" :key="i">
          <v-img max-height="400" contain :src="image.src" />
        </v-col>
      </v-row>
    </div>
    <div class="text-center mt-8" v-if="imgRef != 0">
      Ref
      <v-row>
        <v-col cols="3" v-for="(image, i) in imgRef" :key="i">
          <v-img max-height="400" contain :src="image.src" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import imjson from '@/assets/imagesJson.json'

export default {
  data: () => ({
    images: imjson
  }),
  computed: {
    imgHead: function() {
      let tab = []
      this.images.forEach(image => {
        if (image.type == 'head') {
          tab.push(image)
        }
      })
      return tab
    },
    imgHalf: function() {
      let tab = []
      this.images.forEach(image => {
        if (image.type == 'half') {
          tab.push(image)
        }
      })
      return tab
    },
    imgFull: function() {
      let tab = []
      this.images.forEach(image => {
        if (image.type == 'full') {
          tab.push(image)
        }
      })
      return tab
    },
    imgRef: function() {
      let tab = []
      this.images.forEach(image => {
        if (image.type == 'ref') {
          tab.push(image)
        }
      })
      return tab
    }
  }
}
</script>
